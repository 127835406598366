import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import useInitialExamsData from "../hooks/useInitialExamsData";
import useInitialResultsData from "../hooks/useInitialResultsData";
import axios from "axios";
import { changeLoading, logout } from "../slices/userReducer";
import ButtonContent from "./ButtonContent";
import useInitialFinanceData from "../hooks/useInitialFinanceData";

export default function ContentOptions() {
  const { role, config, id, IDPacient } = useSelector((state) => state.user);
  const [dateFrom] = useState("");
  const [dateTo] = useState("");
  const token = localStorage.getItem("token");
  // eslint-disable-next-line no-unused-vars
  const [pacientResults, setPacientResults] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { fetchExaminationData } = useInitialExamsData();
  const { fetchResultsData } = useInitialResultsData();
  const { fetchFinanceData } = useInitialFinanceData();

  useEffect(() => {
    (async () => {
      dispatch(changeLoading(true));
      await new Promise((resolve) => setTimeout(resolve, 200));
      try {
        if (config.directLoadAffiliate && role === "affiliate") {
          await handleResultsClick();
        }
        if (config.directLoadDoctor && role === "doctor") {
          await handleResultsClick();
        }
      } catch (error) {
        console.error("Error loading results:", error);
      } finally {
        dispatch(changeLoading(false));
      }
    })();
  }, [role, config.directLoadAffiliate, config.directLoadDoctor]);

  const handleExaminationsClick = async () => {
    dispatch(changeLoading(true));
    const examinationData = await fetchExaminationData({
      dateTo,
      dateFrom,
    });
    if (role === "patient") {
      navigate("/examinations", { state: { data: examinationData } });
    }
    if (role === "doctor") {
      navigate("/doctorexaminations", { state: { data: examinationData } });
    }
    if (role === "affiliate") {
      navigate("/affiliateexaminations", {
        state: { data: examinationData },
      });
    }
    if (role === "user") {
      dispatch(logout());
      navigate("/");
    }
    dispatch(changeLoading(false));
  };

  const handleResultsClick = async () => {
    dispatch(changeLoading(true));
    // await new Promise((resolve) => setTimeout(resolve, 5000));
    const examinationData = await fetchResultsData({
      dateTo,
      dateFrom,
    });

    if (role === "patient") {
      navigate("/results", { state: { data: examinationData } });
    } else if (role === "doctor") {
      navigate("/doctorresults", { state: { data: examinationData } });
    } else if (role === "affiliate") {
      navigate("/affiliateresults", { state: { data: examinationData } });
    } else if (role === "user") {
      dispatch(logout());
      navigate("/");
    }
    dispatch(changeLoading(false));
  };
  const handleDocumentsClick = async () => {
    dispatch(changeLoading(true));
    try {
      const requestBody = {
        IDPacientGMedics: id,
        IDPacientGLab: IDPacient,
        DateFrom: "",
        DateTo: "",
      };
      const response = await axios.post(
        `${config.apiBaseUrl}/GLabService/GetOtherDocuments${
          role.charAt(0).toUpperCase() + role.slice(1)
        }`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const fetchedData = response.data;
      setPacientResults(fetchedData);

      navigate("/documents", { state: { data: response.data } });
    } catch (error) {
      console.error("Error fetching examination results:", error);
    } finally {
      dispatch(changeLoading(false));
    }
  };
  const handleFinanceClick = async () => {
    dispatch(changeLoading(true));
    const financeData = await fetchFinanceData({
      dateTo,
      dateFrom,
      isFullDetails: true,
    });
    navigate("/detailedReference", { state: { data: financeData } });

    dispatch(changeLoading(false));
  };
  const shouldRenderMenuItem = (role, path) => {
    if (role === "patient" && config.financeButtonPatient === "true") {
      return path === "/patient" && !path.includes("Finance");
    }
    if (role === "doctor" && config.financeButtonDoctor === "true") {
      return path === "/doctor" && !path.includes("Finance");
    }
    if (role === "affiliate" && config.financeButtonAffiliate === "true") {
      return path === "/affiliate" && !path.includes("Finance");
    }
    return false;
  };
  return (
    <div className="content-options">
      <div className="button-container">
        {((role === "patient" &&
          `${config.examinationButtonPatient}` === "true") ||
          (role === "doctor" &&
            `${config.examinationButtonDoctor}` === "true") ||
          (role === "affiliate" &&
            `${config.examinationButtonAffiliate}` === "true")) && (
          <ButtonContent
            onClick={handleExaminationsClick}
            header="Прегледи"
            description="Амбулаторни данни за проведени
            медицински прегледи"
            icon={<KeyboardArrowRightIcon className="arrowright" />}
          />
        )}
        <ButtonContent
          onClick={handleResultsClick}
          header="Резултати"
          description="Резултати от извършени медико-диагностични изследвания"
          icon={<KeyboardArrowRightIcon className="arrowright" />}
        />
        {role === "patient" && (
          <ButtonContent
            onClick={handleDocumentsClick}
            header="Документи"
            description="Други документи/изследвания"
            icon={<KeyboardArrowRightIcon className="arrowright" />}
          />
        )}
        {role !== "patient" && role !== "doctor" && role !== "affiliate" && (
          <ButtonContent
            onClick={handleDocumentsClick} // handle/function to be changed when the API is ready
            header="Направи поръчка"
            description=" Заявете автоматично информация за себе си или въведете сами"
            icon={<KeyboardArrowRightIcon className="arrowright" />}
          />
        )}
        {shouldRenderMenuItem(role, location.pathname) && (
          <ButtonContent
            onClick={handleFinanceClick}
            header="Справки"
            description="Информация за дейността"
            icon={<KeyboardArrowRightIcon className="arrowright" />}
          />
        )}
      </div>
    </div>
  );
}
