import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { dataLogin } from "../utils";
import { useValidateForm } from "../hooks/useValidateForm";
import { Footer, LoginFormFull } from "../Components";
import RoomIcon from "@mui/icons-material/Room";
import PhoneIcon from "@mui/icons-material/Phone";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
export default function LoginChoice() {
  const { config } = useSelector((state) => state.user);
  const [activeForm, setActiveForm] = useState("");
  const { validateForm, toastVisible, lockMessage } = useValidateForm(
    config,
    activeForm
  );

  const toggleForm = (formId) => {
    setActiveForm(activeForm === formId ? "" : formId);
  };

  return (
    <Fragment>
      <div className="login-choice-content">
        <div className="container">
          <div className="loginPage-info">
            {config?.address?.length > 0 && (
              <div id="address" style={{ color: config.appBarColor }}>
                <RoomIcon
                  className="icon"
                  sx={{ color: config.footerBarColor, bgcolor: "#fff" }}
                />
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                    config.address
                  )}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: config.footerBarColor,
                  }}
                >
                  Адрес: {config.address}
                </a>
              </div>
            )}
            {config?.phone?.length > 0 && (
              <div id="phone" style={{ color: config.appBarColor }}>
                <PhoneIcon
                  className="icon"
                  sx={{ color: config.footerBarColor, bgcolor: "#fff" }}
                />
                <a
                  href={`tel:${config.phone}`}
                  style={{ color: config.footerBarColor }}
                >
                  Телефон: {config.phone}
                </a>
              </div>
            )}

            {config?.email?.length > 0 && (
              <div id="email" style={{ color: config.appBarColor }}>
                <MailOutlineIcon
                  className="icon"
                  sx={{ color: config.footerBarColor, bgcolor: "#fff" }}
                />
                <a
                  href={`mailto:${config.email}`}
                  style={{ color: config.footerBarColor }}
                >
                  Имейл: {config.email}
                </a>
              </div>
            )}
          </div>
          {dataLogin.map((form) => (
            <LoginFormFull
              key={form.buttonId}
              buttonId={form.buttonId}
              buttonOnClick={() => toggleForm(`${form.buttonOnClick}`)}
              buttonText={form.buttonText}
              buttonIcon={form.buttonIcon}
              id={form.id}
              visible={activeForm === form.visible}
              onSubmit={() => validateForm(`${form.onSubmit}`)}
              errors={form.errors}
              inputs={form.inputs}
              placeholder={form.placeholder}
            />
          ))}
        </div>
        <Footer />
        {toastVisible && (
          <div id="toast" className="toast show">
            Неуспешен вход
          </div>
        )}
        {lockMessage && (
          <div id="lock-message" className="lock-message">
            {lockMessage}
          </div>
        )}
      </div>
    </Fragment>
  );
}
