import Menu from "@mui/material/Menu";
import Fade from "@mui/material/Fade";
import React, { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import FolderIcon from "@mui/icons-material/Folder";
import AssignmentIcon from "@mui/icons-material/Assignment";
import MenuDropItem from "./MenuDropItem";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import axios from "axios";
import {
  useInitialExamsData,
  useInitialFinanceData,
  useInitialResultsData,
} from "../../hooks";
import { changeLoading, logout } from "../../slices";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";

export default function FadeMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [icon, setIcon] = useState("menu");
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { role, config, id, IDPacient } = useSelector((state) => state.user);
  const triggerRef = React.useRef(null);
  const [dateFrom] = useState("");
  const [dateTo] = useState("");
  const token = localStorage.getItem("token");
  // eslint-disable-next-line no-unused-vars
  const [patientResults, setPacientResults] = useState([]);
  const { fetchExaminationData } = useInitialExamsData();
  const { fetchResultsData } = useInitialResultsData();
  const { fetchFinanceData } = useInitialFinanceData();
  const handleClick = (event) => {
    // Toggle the menu open/close
    setAnchorEl(anchorEl ? null : triggerRef.current);
    setIcon(icon === "menu" ? "close" : "menu");
  };

  const handleExaminationsClick = async () => {
    dispatch(changeLoading(true));
    const examinationData = await fetchExaminationData({
      dateTo,
      dateFrom,
    });
    if (role === "patient") {
      navigate("/examinations", { state: { data: examinationData } });
      setAnchorEl(null);
      setIcon("menu");
    }
    if (role === "doctor") {
      navigate("/doctorexaminations", { state: { data: examinationData } });
      setAnchorEl(null);
      setIcon("menu");
    }
    if (role === "affiliate") {
      navigate("/affiliateexaminations", { state: { data: examinationData } });
      setAnchorEl(null);
      setIcon("menu");
    }
    dispatch(changeLoading(false));
  };
  const handleResultsClick = async () => {
    dispatch(changeLoading(true));
    const examinationData = await fetchResultsData({
      dateTo,
      dateFrom,
    });
    if (role === "patient") {
      navigate("/results", { state: { data: examinationData } });
      setAnchorEl(null);
      setIcon("menu");
    }
    if (role === "doctor") {
      navigate("/doctorresults", { state: { data: examinationData } });
      setAnchorEl(null);
      setIcon("menu");
    }
    if (role === "affiliate") {
      navigate("/affiliateresults", { state: { data: examinationData } });
      setAnchorEl(null);
      setIcon("menu");
    }
    dispatch(changeLoading(false));
  };
  const handleDocumentsClick = async () => {
    dispatch(changeLoading(true));
    const requestBody = {
      IDPacientGMedics: id,
      IDPacientGLab: IDPacient,
      DateFrom: "",
      DateTo: "",
    };
    const response = await axios.post(
      `${config.apiBaseUrl}/GLabService/GetOtherDocuments${
        role.charAt(0).toUpperCase() + role.slice(1)
      }`,
      requestBody,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const fetchedData = response.data;
    setPacientResults(fetchedData);
    navigate("/documents", { state: { data: fetchedData } });
    setAnchorEl(null);
    setIcon("menu");
    dispatch(changeLoading(false));
  };
  const handleFinanceClick = async () => {
    dispatch(changeLoading(true));
    const financeData = await fetchFinanceData({
      dateTo,
      dateFrom,
      isFullDetails: true,
    });
    navigate("/detailedReference", { state: { data: financeData } });
    setAnchorEl(null);
    setIcon("menu");
    dispatch(changeLoading(false));
  };
  const handleLogout = () => {
    localStorage.clear();
    dispatch(logout());
    navigate("/");
  };
  const shouldRenderMenuItem = (role, path) => {
    if (role === "patient" && config.financeButtonPatient === "true") {
      return !path.includes("detailed");
    }
    if (role === "doctor" && config.financeButtonDoctor === "true") {
      return !path.includes("detailed");
    }
    if (role === "affiliate" && config.financeButtonAffiliate === "true") {
      return !path.includes("detailed");
    }
    return false;
  };
  return (
    <div>
      <div
        ref={triggerRef}
        id="fade-button"
        aria-controls={open ? "fade-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{
          backgroundColor: config.dropdownMenuRootColor,
          left: 0,
        }}
      >
        {icon === "menu" ? (
          <MenuIcon
            onClick={handleClick}
            sx={{
              color: "#fff",
              backgroundColor: config.dropdownMenuRootColor,
              fontSize: "37px",
            }}
          />
        ) : (
          <CloseIcon
            onClick={handleClick}
            sx={{
              color: "#fff",
              backgroundColor: config.dropdownMenuRootColor,
              fontSize: "40px",
              zIndex: "10000 !important",
            }}
          />
        )}
      </div>
      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
          flexgrow: 1,
          height: "100%",
        }}
        anchorEl={triggerRef.current}
        open={open}
        TransitionComponent={Fade}
        sx={{
          width: "365px",
          height: "calc(100vh + 64px)",
          top: 54,
          zIndex: "26000",
          //vertical
          "@media (max-width: 360px)": {
            width: "80%",
            marginTop: "-10px",
          },
          "@media (min-width: 361px) and (max-width: 640px)": {
            width: "80%",
            marginTop: "-10px",
          },
          //horizontal
          "@media (max-width: 640px) and (max-height: 360px)": {
            width: "50%",
          },
          "& div.MuiPaper-root": {
            width: "100%",
          },
          "& .MuiList-root": {
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          },
          "& .MuiBackdrop-root": {
            display: "none",
          },
          "& .MuiPaper-root": {
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100vh",
            borderRadius: "0px",
            margin: 0,
            backgroundColor: config.dropdownMenuRootColor,
            color: "#fff",
            padding: "0",
            alignItems: "center",
            right: "0px !important",
            "@media (max-width: 360px)": {
              width: "80%",
            },

            "@media (min-width: 681px) and (max-width: 940px)": {
              width: "80%",
            },
            "& .MuiMenuItem-root": {
              "@media (max-width: 360px)": {
                width: "100%",
                marginBottom: "10px",
                height: "50px",
                minHeight: "50px",
              },
              "@media (min-width: 361px) and (max-width: 640px)": {
                width: "100%",
              },
              "@media (min-width: 641px) and (max-width: 940px)": {
                width: "110%",
                marginBottom: "10px",
              },
              "@media (max-width: 640px) and (max-height: 360px)": {
                width: "100%",
                marginBottom: "5px",
                fontSize: "12px",
                height: "35px",
                minHeight: "35px",
              },
            },
          },
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <div
          style={{
            flexGrow: 1,
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start", // Ensures top items start from the top
            marginBottom: "auto", // Allow space to push exit to the bottom
          }}
        >
          {/* or if include 'examinations' */}
          {((role === "patient" &&
            `${config.examinationButtonPatient}` === "true") ||
            (role === "doctor" &&
              `${config.examinationButtonDoctor}` === "true") ||
            (role === "affiliate" &&
              `${config.examinationButtonAffiliate}` === "true")) &&
            location.pathname !== "/examinations" &&
            location.pathname !== "/doctorexaminations" &&
            location.pathname !== "/affiliateexaminations" && (
              <MenuDropItem
                text="Прегледи"
                onClick={handleExaminationsClick}
                icon={<LocalHospitalIcon />}
                sx={{
                  "&:hover .MuiSvgIcon-root": {
                    color: "#fff",
                  },
                }}
              />
            )}
          {location.pathname !== "/results" &&
            location.pathname !== "/doctorresults" &&
            location.pathname !== "/affiliateresults" && (
              <MenuDropItem
                text="Резултати"
                onClick={handleResultsClick}
                icon={<AssignmentIcon />}
                sx={{
                  "&:hover .MuiSvgIcon-root": {
                    color: "#fff",
                  },
                }}
              />
            )}
          {location.pathname !== "/documents" &&
            role !== "doctor" &&
            role !== "affiliate" && (
              <MenuDropItem
                text="Документи"
                onClick={handleDocumentsClick}
                icon={<FolderIcon />}
                sx={{
                  "&:hover .MuiSvgIcon-root": {
                    color: "#fff",
                  },
                }}
              />
            )}
          {role !== "doctor" && role !== "patient" && role !== "affiliate" && (
            <MenuDropItem text="Направи поръчка" onClick={handleClick} />
          )}
          {role !== "doctor" && role !== "patient" && role !== "affiliate" && (
            <MenuDropItem text="Профил" onClick={handleClick} />
          )}
          {shouldRenderMenuItem(role, location.pathname) && (
            <MenuDropItem
              text="Справки"
              onClick={handleFinanceClick}
              icon={<PaidOutlinedIcon />}
              sx={{
                "&:hover .MuiSvgIcon-root": {
                  color: "#fff",
                },
              }}
            />
          )}
        </div>
        <div style={{ width: "100%", marginTop: "auto", marginBottom: "12vh" }}>
          <MenuDropItem
            text="Изход"
            onClick={handleLogout}
            sx={{
              color: config.dropdownMenuRootColor,
              backgroundColor: "white",
              width: "100%",
              justifyContent: "center",
            }}
          />
        </div>
      </Menu>
    </div>
  );
}
